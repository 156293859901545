import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { mixins } from "@/plugins/mixins";
export default {
  name: "dataUpload",
  mixins: [mixins],
  components: {},
  data() {
    return {
      searchItems: [{
        label: '文件名称',
        prop: 'fileName'
      }, {
        prop: 'importStatus',
        label: '状态',
        type: 'select',
        items: ['待处理', '处理中', '导入成功', '导入失败']
      }],
      columns: [{
        label: '文件名称',
        prop: 'fileName'
      }, {
        label: '状态',
        prop: 'importStatus'
      }, {
        label: '开始处理时间',
        prop: 'beginTime'
      }, {
        label: '结束时间',
        prop: 'endTime'
      }, {
        label: '错误文件',
        prop: 'errorFilePath',
        customRender: props => {
          if (props.row.errorFilePath) {
            return _createVNode(_resolveComponent("el-link"), {
              "type": "primary",
              "underline": false,
              "onClick": () => {
                this.downFile(props.row);
              }
            }, {
              default: () => [_createTextVNode("\u4E0B\u8F7D\u9519\u8BEF\u6587\u4EF6"), _createVNode("i", {
                "className": "el-icon-view el-icon--right"
              }, null)]
            });
          } else {
            return null;
          }
        }
      }],
      topButtons: [],
      linkButtons: []
    };
  },
  methods: {
    downFile(val) {
      window.open(val.errorFilePath, "_blank");
    }
  }
};