import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_layout_breadcrumb = _resolveComponent("layout-breadcrumb");
  const _component_table_page = _resolveComponent("table-page");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_layout_breadcrumb, {
    topMenuId: _ctx.topMenuId,
    onChangeTopToMenu: _ctx.changeTopToMenu,
    topMenuClick: _ctx.topMenuClick
  }, null, 8, ["topMenuId", "onChangeTopToMenu", "topMenuClick"]), _createVNode(_component_table_page, {
    fetchApi: _ctx.$api.business.dataUpload.getList,
    columns: $data.columns,
    topButtons: $data.topButtons,
    linkButtons: $data.linkButtons,
    searchItems: $data.searchItems,
    selectionEnable: false,
    ref: "tablePage"
  }, null, 8, ["fetchApi", "columns", "topButtons", "linkButtons", "searchItems"])]);
}